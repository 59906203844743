<template>
  <div
    v-if="data"
    :class="{
      'fixed top-0 left-0 w-full h-screen z-50 flex flex-col': data.params.map,
    }"
  >
    <header
      class="search-page-header bg-green-alt md:bg-green-alt py-20 lg:py-30 relative"
    >
      <Container>
        <NavigatorSearchBar key="navigator-search-bar" />
      </Container>
    </header>

    <NavigatorFilterBar :facets="data.facets" />

    <main v-if="data.params.map" class="flex-1 relative">
      <ClientOnly>
        <LazyNavigatorMapMapbox
          :hits="data.hits"
          :search-coordinates-query="coordinatesQuery"
        >
          <div
            class="absolute top-[18px] left-0 z-20 p-10 right-50 md:pr-70 md:top-0"
          >
            <NavigatorSelected
              :text="textQuery"
              :facets="data.params.facets"
              :location="locationQuery"
            />
          </div>
        </LazyNavigatorMapMapbox>
      </ClientOnly>
    </main>

    <main v-else class="pt-20 md:pt-40">
      <Container>
        <NavigatorSelected
          :text="textQuery"
          :facets="data.params.facets"
          :location="locationQuery"
          class="flex-1 mb-15"
        />
        <h1
          class="mb-20 lg:mb-30 pb-10 border-b border-b-gray-300 font-semibold text-md lg:text-lg xl:text-xl"
        >
          {{ resultsTitle }}
        </h1>

        <div
          ref="scrollToEl"
          class="lg:flex lg:gap-20 flex-wrap items-stretch scroll-mt-40"
        >
          <div class="md:order-1 flex-1">
            <div class="grid md:gap-20 lg:gap-30">
              <p v-if="!hasResults">
                {{
                  $texts(
                    'navigator.noResultsText',
                    'Für Ihre Suche konnten leider keine Apotheken gefunden werden. Bitte versuchen Sie Ihre Suche mit weniger Filtern.',
                  )
                }}
              </p>

              <NavigatorContentTeaser
                :service-ids="contentTeaserServiceIds"
                :text="textQuery"
                class="mb-20 md:mb-0"
              />

              <NavigatorFulltextTeaser
                v-if="textQuery && !hasResults"
                :text="textQuery"
                class="mt-20"
              />

              <NavigatorHit
                v-for="hit in data.hits"
                v-bind="hit"
                :key="hit.id"
              />
            </div>
            <div
              v-if="data.totalPages > 1"
              class="w-full md:order-3 my-20 xl:my-30 min-w-full md:py-20 md:bg-gray-50 lg:sticky lg:bottom-0 md:z-20 md:px-20"
            >
              <Pagination
                :page="data.params.page"
                :total-pages="data.totalPages"
                :is-small="true"
                class="md:-mx-20"
                @change-page="onChangePage"
              />
            </div>
          </div>

          <div
            class="md:order-2 lg:w-300"
            :class="{
              'sticky bottom-[-140px]': total,
            }"
          >
            <template v-if="total">
              <div
                class="sticky md:top-90 mobile-only:bottom-[-140px] mobile-only:-mx-20"
              >
                <NuxtLink
                  :to="mapViewUrl"
                  class="box h-200 lg:h-[400px] flex items-center justify-center navigator-map-teaser"
                >
                  <img
                    src="/map-teaser.png"
                    class="absolute top-0 left-0 w-full h-full object-cover"
                  />
                  <div class="py-10 sticky bottom-0">
                    <div class="button is-narrow relative z-20">
                      <SpriteSymbol
                        name="marker-outline"
                        class="w-20 h-20 mr-10"
                      />
                      <span>{{
                        $texts('navigator.showMap', 'Karte anzeigen')
                      }}</span>
                    </div>
                  </div>
                </NuxtLink>
                <NavigatorFulltextTeaser
                  :text="textQuery"
                  if="textQuery"
                  class="mt-20 hidden lg:block"
                />
              </div>
            </template>
          </div>
        </div>
      </Container>
    </main>
  </div>
</template>

<script lang="ts" setup>
import type { NodePageFragment } from '#graphql-operations'
import type { SearchPharmaciesResponse } from '~/sites/pharmago/helpers/pharmacies-api/types'

definePageMeta({
  name: 'navigator',
  isReduced: (route: any) => {
    return route.query.map === 'true'
  },
  noHeaderShadow: true,
  staticDrupalPage: 'pharmago_navigator',
  drupalRoute: true,
})

const language = useCurrentLanguage()
const route = useRoute()
const { $texts } = useNuxtApp()

const textQuery = useQueryString('text')
const locationQuery = useQueryString('location')
const coordinatesQuery = useQueryString('coordinates')
const mapModeQuery = computed(() => route.query.map === 'true')

const fetchQuery = computed(() => ({
  ...route.query,
  langcode: language.value,
  size: 16,
  text: textQuery.value,
  coordinates: coordinatesQuery.value,
  map: mapModeQuery.value,
}))

const { data, error } = await useFetch<SearchPharmaciesResponse>(
  '/api/pharmacies',
  {
    query: fetchQuery,
  },
)

/**
 * Throw an exception if Elasticsearch is not available.
 */
const throwSearchNotAvailable = () => {
  throw createError({
    statusCode: 500,
    fatal: true,
    statusMessage: $texts(
      'navigator.elasticSearchNotAvailableTitle',
      'Apothekensuche nicht verfügbar',
    ),
    message: $texts(
      'navigator.elasticSearchNotAvailableText',
      'Die Apothekensuche steht zur Zeit nicht zur Verfügung. Bitte versuchen Sie es später noch einmal.',
    ),
  })
}

if (error.value) {
  throwSearchNotAvailable()
}

watch(error, throwSearchNotAvailable)

watch(fetchQuery, () => {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  })
})

const total = computed(() => data.value?.total || 0)

const resultsTitle = computed(() => {
  if (total.value === 0) {
    return $texts('navigator.titleNoResults', 'Keine Apotheken gefunden')
  } else if (total.value === 1) {
    return $texts('navigator.titleOneResult', '1 Apotheke gefunden')
  }

  return $texts(
    'navigator.titleManyResults',
    '@count Apotheken gefunden',
  ).replace('@count', total.value.toString())
})

const mapViewUrl = computed(() => {
  return {
    name: route.name!,
    params: route.params,
    query: {
      ...route.query,
      page: undefined,
      mapCoords: coordinatesQuery.value || undefined,
      mapZoom: coordinatesQuery.value ? 22 : undefined,
      map: 'true',
    },
  }
})

const contentTeaserServiceIds = computed<string[]>(
  () =>
    data.value?.params.facets
      .filter((v) => v.field === 'services')
      .map((v) => v.value) || [],
)

const hasResults = computed(() => !!data.value?.hits.length)

// Get the data.
const { data: query } = await useAsyncData('route', () =>
  useGraphqlQuery('route', {
    path: route.path,
  }).then((v) => v.data),
)

const scrollToEl = ref<HTMLDivElement | null>(null)
const { onChangePage } = usePaginationChange(scrollToEl)

await useDrupalRoute<NodePageFragment>(query.value, {
  noError: true,
})

setBreadcrumbLinks()
setLanguageLinksFromRoute(query.value)

useRouteCache((c) => c.setCacheable().setMaxAge(600))
await renderPageDependencies()
</script>

<script lang="ts">
export default {
  name: 'PagePharmagoNavigator',
}
</script>

<style lang="postcss">
.navigator-sticky-background {
  --tw-gradient-stops: var(--tw-gradient-from) 50%, var(--tw-gradient-to);
}

.navigator-map-teaser {
  box-shadow: 0px -4px 15px 0px rgba(0, 0, 0, 0.13);
  @apply md:shadow-md;
}
</style>
