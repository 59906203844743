<template>
  <div
    v-if="items.length"
    class="flex gap-5 lg:gap-10 whitespace-nowrap mobile-only:overflow-auto mobile-only:px-20 mobile-only:-mx-20 text-xs md:text-sm md:flex-wrap"
  >
    <NuxtLink
      v-for="(item, i) in items"
      :key="i"
      class="flex justify-between items-center bg-white font-medium rounded px-[0.6em] py-3 hover:bg-gray-100"
      :to="item.to"
      :title="$texts('navigator.removeSelectedFacet', 'Filter entfernen')"
    >
      <span class="mobile-only:max-w-[100px] truncate min-w-0 inline-block">{{
        item.label
      }}</span>
      <SpriteSymbol name="close" class="is-text-sized fill-white ml-5" />
    </NuxtLink>
    <NuxtLink
      v-if="facets.length > 1"
      class="flex justify-between items-center bg-green-alt text-white font-medium rounded px-15 py-3 hover:bg-lime"
      :to="resetUrl"
    >
      {{ $texts('navigator.resetAllFilters', 'Alle Filter entfernen') }}
    </NuxtLink>
  </div>
</template>

<script lang="ts" setup>
import type { RouteLocationRaw } from 'vue-router'
import { SearchParamsFacet } from '~/sites/pharmago/helpers/pharmacies-api/types'

const { buildFacetTermLink, getFacetLabel, isSingleFacet } = useNavigator()
const route = useRoute()
const { $texts } = useNuxtApp()

const props = defineProps<{
  text: string
  location: string
  facets: SearchParamsFacet[]
}>()

type SelectedItem = {
  to: RouteLocationRaw
  label: string
}

const items = computed<SelectedItem[]>(() => {
  const mapped: SelectedItem[] = [
    ...props.facets.map((v) => {
      return {
        to: buildFacetTermLink(v.field, v.value, true),
        label: getLabel(v),
      }
    }),
  ]
  if (props.location) {
    mapped.unshift({
      to: {
        name: route.name!,
        query: {
          ...route.query,
          location: undefined,
          coordinates: undefined,
          page: undefined,
        },
      },
      label:
        props.location === 'current'
          ? $texts('navigator.currentLocation', 'Aktueller Standort')
          : props.location,
    })
  }
  if (props.text) {
    mapped.unshift({
      to: {
        name: route.name!,
        query: {
          ...route.query,
          text: undefined,
          page: undefined,
        },
      },
      label: props.text,
    })
  }
  return mapped
})

function getLabel(v: SearchParamsFacet): string {
  if (isSingleFacet(v.field)) {
    return getFacetLabel(v.field)
  }

  if (v.field === 'has_open_positions') {
    return $texts('navigator.openPositions', 'Offene Stellen')
  }

  return v.label || v.value
}

const resetUrl = computed(() => {
  return {
    name: route.name!,
    params: route.params,
    query: {
      ...route.query,
      facets: undefined,
      page: undefined,
    },
  }
})
</script>
